import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle` 
  * {
      font-family: 'Poppins', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #46d5db;
    border-radius: 8px;
  }
  .white_box {
    box-shadow: -4px 5px 10px 2px rgb(0 0 0 / 20%);
  }
  button {
    outline: none !important;
  }
  a:hover {
    text-decoration: none !important;
    color: #e69f35 !important;
  }
  button.slip_btn {
    color: #fff;
  }
  .slip_inp {
    color: #e69f35;
  }
  .header_btn {
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
    height: 40px !important;
    background-color: #e69f35 !important;
    color: #fff;
    letter-spacing: 0px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
  }
  #swap-currency-input input::placeholder,
  #swap-currency-output input::placeholder,
  #add-liquidity-input-tokena input::placeholder,
  #add-liquidity-input-tokenb input::placeholder {
    color: #e69f35;
  }
  #swap-currency-input div,
  #swap-currency-output div,
  #add-liquidity-input-tokena div,
  #add-liquidity-input-tokenb div {
    color: #37373e;
  }
  #swap-currency-input input {
    font-weight: 600 !important;
    color: #e69f35;
  }
  #swap-currency-output input {
    font-weight: 600 !important;
    color: #e69f35;
  }
  #add-liquidity-input-tokena input,
  #add-liquidity-input-tokenb input {
    font-weight: 600 !important;
    color: #e69f35;
  }
  .toggle_wrapper div {
    background-color: #3c3742;
    border-radius: 30px;
  }
  .toggle_wrapper div a {
    background-color: #3c3742;
  }
  .toggle_wrapper div a.active {
    background-color: #000;
    color: #FFFFFF;
  }
  button.farms_unlock_btn,
  a.farms_unlock_btn {
    width: 100%;
    height: 40px;
    background-color: #e69f35 !important;
    border: 1px solid #e69f35;
    color: #fff !important;
    border-radius: 5px;
    outline: none !important;
  }
  .menulink:hover {
    background-color: #e69f35;
  }
  button.wallet_btn {
    background-color: #fff;
    border-radius: 5px;
    opacity: 1 !important;
  }
  button.wallet_btn:hover {
    background-color: #e69f35 !important;
  }
  button.open-currency-select-button {
    background-color: #e69f35 !important;
  }
  button.open-currency-select-button div {
    color: #fff !important;
  }
  .tooltip_bg div {
    background: transparent;
  }
  button.swap_arrow_btn {
    background: #fff;
  }
  .sort_btn div:nth-child(2) {
    background-color: #e69f35;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  .seperator {
    background-color: #31bcc2;
  }
  #token-search-input {
    border: 1px solid #31bcc2;
  }
  #token-search-input::placeholder {
    color: #fff;
  }
  .token_item_list div {
    color: #000;
  }
  .token_item_list:hover {
    background-color: #e69f35;
  }
  .token_item_list:hover div {
    color: #fff;
  }


  @media screen and (max-width: 559px) {
    input[type="number"] {
      margin-top: 15px;
    }
  }
`

export default GlobalStyle
